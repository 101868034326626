import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import DisplayImage from './DisplayImage';
import Media from './Media';
import CreateLink from './CreateLink';
import { fetchImage } from '../utils/helper';
import '../assets/styles/templates/sketchbook.scss';

function Sketchbook({ article }) {
  const {
    title,
    featuredimage,
    featuredimageGatsby,
    tag,
    featuredimage_alt,
    sketchbookcontent,
  } = article;
  const gettingImage = useCallback((gatsbyImage, image) => fetchImage(gatsbyImage, image), []);

  return (
    <div className="page-section two-column-text--wrapper container">
      <div className="two-column-text--column-one">
        <h1>{title}</h1>
      </div>
      <div className="two-column-text--column-two">
        <DisplayImage
          img={featuredimageGatsby || featuredimage}
          alt={featuredimage_alt}
          icon={featuredimageGatsby || featuredimage}
        />
        <strong className="sketchbook-single--page-tag">
          {tag}
        </strong>
        {sketchbookcontent.map((item) => {
          if (item.type === 'content_text') {
            return (
              <div
                key={item.text}
                className="sketchbook-single--page-description"
              >
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {item.text}
                </ReactMarkdown>
              </div>
            );
          }
          if (item.type === 'content_media') {
            return (
              <div className="sketchbook-single--page-media-image" key={item.image_alt}>
                <Media
                  img={gettingImage(item.imageGatsby, item.image)}
                  alt={item.image_alt}
                  htmlVideo={item.video}
                  vimeoId={item.vimeo}
                />
              </div>
            );
          }

          return null;
        })}
        <CreateLink link="/sketchbook" classWrapper="sketchbook-single--page-cta-link cta-link">
          View all articles
        </CreateLink>
      </div>
    </div>
  );
}

Sketchbook.propTypes = {
  article: PropTypes.shape().isRequired,
};

export default Sketchbook;
