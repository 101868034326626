import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import SketchbookTemplate from '../components/Sketchbook';

function Sketchbook({ data }) {
  if (data.sketchbooks === null) {
    return null;
  }
  const { content } = data.sketchbooks;
  const {
    seo_description,
    seo_title,
  } = content;
  const isTrue = true;

  return (
    <Layout classWrapper="header-not-over sketchbook-single--page" headerDark={isTrue}>
      <SEO
        title={seo_title}
        description={seo_description}
      />
      <SketchbookTemplate article={content} />
    </Layout>
  );
}

Sketchbook.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Sketchbook;

export const SketchbookQuery = graphql`
  query SketchbookPage($id: String!) {
    sketchbooks(content: {id: {eq: $id}}) {
      content {
        sketchbookcontent {
          imageGatsby {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
            url
          }
          image
          image_alt
          text
          type
          video
          vimeo
        }
        title
        seo_description
        seo_title
        featuredimageGatsby {
          childImageSharp {
            gatsbyImageData
          }
          publicURL
        }
        tag
        featuredimage_alt
      }
    }
  }
`;
